<template>
  <div class="sm:p-8 text-blue-dark relative flex flex-col items-center justify-center p-4">
    <p class="sm:text-lg absolute top-0 tracking-wide text-center text-white bg-red-700 rounded-b-lg shadow">
      <a href="/" class="flex w-full h-full px-6 pt-0.5 pb-1.5">{{ $t("Zina Design Studio") }}</a>
    </p>
    <h2 class="xl:text-6xl sm:font-normal mt-16 mb-16 text-4xl font-semibold text-center text-gray-700 uppercase">
      {{ $t("To order a site") }}
    </h2>
    <div class=" mb-8">
      <form-order 
        :formId="'form-navbar'"
        :formDescription="'Форма «Заказать сайт» в модальном окне, кнопка в навбаре'"
        :formCta="
          $i18n.locale === 'uk'
            ? 'ЗАМОВИТИ САЙТ'
            : 'ЗАКАЗАТЬ САЙТ'
        "
        :pageTitle="
          $i18n.locale === 'uk'
            ? 'Студія «Зіна дизайн»'
            : 'Студия «Зина дизайн»'
        "
      ></form-order>
    </div>
  </div>
  <!-- <form-modal-footer></form-modal-footer> -->
</template>
<script>

import { defineAsyncComponent } from "vue";
const FormOrder = defineAsyncComponent(() => import("@/components/UI/FormOrder"));
// const FormModalFooter = defineAsyncComponent(() => import("@/components/UI/FormModalFooter"));

export default {
  name: "order-modal",
  components: {
    FormOrder,
    // FormModalFooter,
  },
}
</script>

